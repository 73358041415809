

/* Full Page Background Image Section */
.bg-full-page {
  background-image: url('https://m.economictimes.com/thumb/msid-112109662,width-1600,height-900,resizemode-4,imgsize-94094/ai-automation.jpg'); /* Replace with your image URL */
  background-size: cover;
  background-position: center;
  min-height: 100vh; /* Full page height */
  display: flex;
  align-items: center; /* Vertically center the content */
  justify-content: center; /* Horizontally center */
  position: relative;
  color: white;
}

.caption-container {
  position: absolute;
  top: 20px;
  left: 20px;
  padding: 20px;
  border-radius: 8px;
}

/* Scrollable Cards Section */
.scrollable-cards-section {
  background-color: white;
  min-height: 100vh; /* Full page height for the cards section */
  padding: 40px 0;
}

.card-container {
  display: flex;
  overflow-x: auto; /* Horizontal scrolling */
  scroll-behavior: smooth;
  gap: 20px; /* Space between cards */
}

.card-item {
  flex: 0 0 300px; /* Each card is fixed in width */
  margin-right: 20px; /* Space between each card */
}

.card-item img {
  object-fit: cover;
  height: 500px;
}

.card-item a {
  font-size: 1.25rem;
  font-weight: bold;
  text-decoration: none;
  color: #000;
}

.card-item a:hover {
  color: #007bff; /* Change color on hover */
}
/* Search Bar Styling */
.search-bar-container {
  position: relative;
  display: flex;
  align-items: center;
}

.search-bar-container input {
  width: 200px;
  transition: width 0.4s ease;
}

@media (min-width: 768px) {
  .search-bar-container input {
    width: 300px;
  }
}




/* Ensure text content section has proper margins and alignment */
.text-content {
  text-align: center;
  margin: 40px 0;
  font-size: 3rem; /* Adjust font size as needed */
  font-weight: bold; /* Bold font weight for prominent text */
  margin: 0; /* Remove default margin */
  text-align: center; /* Center-align the text */
}

/* Styling for the horizontal layout */
/* Ensure full-width dropdown */
/* Header.css */

/* Full-width submenu styling */
.full-width-submenu {
  position: absolute; /* Position the submenu relative to the parent */
  top: 4.3rem; /* Adjust based on your header height */
  left: 0;
  right: 0;
  z-index: 1000; /* Ensure it appears above other content */
  display: none; /* Initially hidden */
  padding: 1rem; /* Add some padding */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Shadow for depth */
}

/* Active submenu (when displayed) */
.applications-submenu,
.product-type-submenu,
.features-technologies-submenu,
.snapdragon-submenu {
  position: absolute;
  background-color: #f8f9fa; /* Light background */
  width: 100vw; /* Full viewport width */
  left: 0; /* Align with the left edge */
  display: none; /* Initially hidden */
  z-index: 10; /* Ensure it overlaps other content */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px 0; /* Padding for top and bottom */
}

.applications-submenu.active,
.product-type-submenu.active,
.features-technologies-submenu.active,
.snapdragon-submenu.active {
  display: block; /* Show active submenu */
}

.applications-grid {
  display: flex;
  justify-content: space-around; /* Space items evenly */
  max-width: 1200px; /* Optional: max width for content */
  margin: 0 auto; /* Center content */
  padding: 20px; /* Padding for the grid */
}

.applications-grid > div {
  flex: 1; /* Allow items to grow equally */
  margin: 0 10px; /* Spacing between sections */
}

.applications-grid h5 {
  margin-bottom: 10px; /* Space below section headings */
}

.applications-grid ul {
  list-style: none; /* Remove default list styling */
  padding: 0; /* Remove default padding */
}

.applications-grid li {
  margin: 5px 0; /* Space between list items */
}

.applications-grid a {
  text-decoration: none; /* Remove underline */
  color: #007bff; /* Link color */
}

.applications-grid a:hover {
  text-decoration: underline; /* Underline on hover */
}
/* Developer submenu*/


/* Vertical dividers for icons */
.vertical-divider {
  width: 1px;
  height: 20px;
  background-color: #ddd;
}

/* Navbar customizations */
/* Header.css */

/* Header.css */

.header-wrapper {
  position: relative;
  width: 100%;
}

.gradient-border {
  width: 100%;
  height: 4px;
  background: linear-gradient(to left, #E71324, #3278dc);
}

.header {
  margin-top: 4px; /* Ensures the header starts just below the gradient */
}

.container-fluid {
  display: flex;
  justify-content: space-between;
  padding-left: 130px;
  padding-right: 48px;
}

.vertical-divider {
  width: 1px;
  height: 20px;
  background-color: #ccc;
}
