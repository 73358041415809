/* General page styles */
.solutions-page {
    background-image: url(https://img.freepik.com/free-vector/abstract-blue-technology-background_23-2149352058.jpg?size=626&ext=jpg&ga=GA1.1.2021888660.1726555282&semt=ais_hybridhttps://www.shutterstock.com/shutterstock/photos/2128959116/display_1500/stock-vector-abstract-waving-particle-technology-background-design-abstract-wave-moving-dots-flow-particles-hi-2128959116.jpg);
    background-size: cover;
    padding: 291px 20px;
    background-color: #f8f9fa;
    text-align: center;
    animation: fadeIn 1.5s ease-in-out;  /* Fade-in effect for the page */
}

/* Heading styles */
.solutions-heading {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 30px;
    color: white;
    opacity: 0;
    animation: fadeInHeading 1.5s ease-in-out forwards;
}

/* Solutions grid container */
.solutions-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    justify-content: center;
    padding: 20px;
}

/* Card styles */
.solution-card {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    opacity: 0;  /* Initial state for animation */
    transform: scale(0.95);  /* Slightly scaled down initially */
    animation: fadeInUp 0.8s ease forwards;
}

/* Hover effect on cards */
.solution-card:hover {
    transform: scale(1.05);  /* Slight scaling on hover */
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
}

/* Image styles */
.solution-image {
    width: 80px;
    height: 80px;
    margin-bottom: 15px;
}

/* Text styles for card title */
.solution-card h3 {
    font-size: 1.25rem;
    color: #333;
}

/* Text styles for card description */
.solution-card p {
    font-size: 1rem;
    color: #555;
}

/* Animations */
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(30px) scale(0.95);
    }
    100% {
        opacity: 1;
        transform: translateY(0) scale(1);
    }
}

@keyframes fadeInHeading {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Delay animations for each card to create a staggered effect */
.solution-card:nth-child(1) {
    animation-delay: 0.1s;
}

.solution-card:nth-child(2) {
    animation-delay: 0.2s;
}

.solution-card:nth-child(3) {
    animation-delay: 0.3s;
}

.solution-card:nth-child(4) {
    animation-delay: 0.4s;
}

.solution-card:nth-child(5) {
    animation-delay: 0.5s;
}

.solution-card:nth-child(6) {
    animation-delay: 0.6s;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
    .solutions-heading {
        font-size: 2rem;
    }

    .solution-image {
        width: 60px;
        height: 60px;
    }

    .solution-card h3 {
        font-size: 1.1rem;
    }

    .solution-card p {
        font-size: 0.9rem;
    }
}
