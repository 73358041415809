.insights-section {
    padding: 60px 0;
    color: #fff;
  }
  
  .section-title {
    color: #ff6f61; /* Title color */
    font-size: 36px;
  }
  
  .insight-card {
    background-color: rgba(0, 0, 0, 0.7); /* Card with transparent background */
    color: white;
    padding: 20px;
    border-radius: 10px;
    transition: transform 0.3s ease, background-color 0.3s ease;
  }
  
  .insight-card:hover {
    transform: translateY(-10px); /* Card moves up slightly on hover */
    background-color: rgba(255, 111, 97, 0.9); /* Change color on hover */
  }
  
  .card-title {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .animated-card {
    opacity: 0;
    animation: fadeInUp 0.6s ease forwards;
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(30px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Add media queries for responsiveness if needed */
  @media (max-width: 768px) {
    .insight-card {
      margin-bottom: 20px;
    }
  }
  