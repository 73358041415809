
/* Full Page Background Image Section */

.App {
  font-family: 'Roboto Flex', sans-serif;
}
.bg-full-page {
  background-image: url('https://files.oaiusercontent.com/file-RaX5NQkGw95kKY7k5C8VaaAA?se=2024-10-07T03%3A38%3A03Z&sp=r&sv=2024-08-04&sr=b&rscc=max-age%3D604800%2C%20immutable%2C%20private&rscd=attachment%3B%20filename%3D978b7460-329d-4aef-8edb-618767b1a7a5.webp&sig=hGJMJM2EWJbUvIC%2BGM79zVS9JHTMa4gwnOp3l2GvHKo%3D'); /* Replace with your image URL */
  background-size: cover;
  background-position: center;
  min-height: 100vh; /* Full page height */
  display: flex;
 /* Vertically center the content */
  text-align: left; /* Horizontally center */
  position: relative;
  color: white;
  flex-direction: column;
}


.caption-container {
  text-align: left;
  position: absolute;
  margin-bottom: 20px;
  right: 0; /* Align caption to the right */
  top: 30%;
  transform: translateY(-50%);
  display: flex;
  align-items: flex-start;
  flex-flow: column wrap;
  justify-content: flex-start;
  width: 100%;
  box-sizing: inherit;
  color: white;
  padding: 20px; 
}
#richTextContainer {
  word-break: break-word;
  box-sizing: inherit;
  text-align: left;
  
}
.rte-heading-xxxl {
  font-size: 3rem; /* Adjust as needed */
  line-height: 1.4;
  background-position: right center; /* Background positioning */
  font-weight: bold;
  letter-spacing: .01em;
  text-align: left;
}
/* Style the carousel images to have a fixed height */
.carousel-container {
  display: flex;
  justify-content: center; /* Center the carousel */
  width: 100%;
  padding-top: 400px;
}

.carousel-inner img {
  height: auto; /* Maintain aspect ratio */
  max-height: 350px; /* Set a maximum height */
  object-fit: cover; /* Crop image to fit */
}
.home {
  background-image: url(https://png.pngtree.com/thumb_back/fh260/background/20190223/ourmid/pngtree-blue-tech-artificial-intelligence-poster-background-intelligencelight-effecttextureposterbackground-image_68116.jpg);
  background-size: cover;
  position: relative;
  overflow: hidden;
}

.caption-container {
  padding: 20px; /* Adjust padding as needed */
  color: white; /* Change text color if necessary */
  position: absolute; /* Position the caption absolutely */
  top: 400px; /* Position from the top */
  left: 220px; /* Position from the left */
}

.image-container {
  position: relative;
  overflow: hidden;
  height: 100%; /* Ensure the image takes full height */
}
/* Scrollable Cards Section */
.scrollable-cards-section {
  background-color: white;
  min-height: 100vh; /* Full page height for the cards section */
  padding: 40px 0;
}

.card-container {
  display: flex;
  overflow-x: auto; /* Horizontal scrolling */
  scroll-behavior: smooth;
  gap: 20px; /* Space between cards */
}

.card-item {
  flex: 0 0 300px; /* Each card is fixed in width */
  margin-right: 20px; /* Space between each card */
}

.card-item img {
  object-fit: cover;
  height: 500px;
}

.card-item a {
  font-size: 1.25rem;
  font-weight: bold;
  text-decoration: none;
  color: #000;
}

.card-item a:hover {
  color: #007bff; /* Change color on hover */
}

/* Ensure text content section has proper margins and alignment */
.text-content {
  text-align: center;
  margin: 40px 0;
  font-size: 3rem; /* Adjust font size as needed */
  font-weight: bold; /* Bold font weight for prominent text */
  margin: 0; /* Remove default margin */
  text-align: center; /* Center-align the text */
}

/* Black BACKGROUND*/

.black-background-section .card {
  max-width: 400px; /* Adjust this value to reduce card width */
  margin: 10px auto; /* Center cards with auto margin */
}

.black-background-section .card img {
  height: auto; /* Maintain aspect ratio */
  max-height: 150px; /* Set a max height for images to keep cards smaller */
}

.black-background-section .caption {
  max-width: 40%; /* Control the width of the caption section */
}
.card-item {
  border: none; /* Hide card borders */
}

.card {
  border: none; /* Hide card borders */
  box-shadow: none; /* Remove shadow */
}

.card-title {
  text-align: center; /* Center card titles */
}

.black-background-section {
  background-color: black; /* Set background color */
  color: white; /* Set text color */
  padding: 40px 0; /* Adjust padding */
}

.caption {
  color: white; /* Caption text color */
  margin-right: auto; /* Align caption to the left */
}
/* CSS for hover effect */
.card-hover {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card-hover:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}


/* darkblue Section*/
.dark-blue-section {
  background-color: #001f3f;
  padding: 60px 0;
}

.dark-blue-section img {
  width: 50%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.dark-blue-section h2,
.dark-blue-section p {
  color: #fff;
  text-align: center;
}

.dark-blue-section .btn {
  margin-top: 20px;
}

/* Why Choose Us Section */
.why-choose-us {
  padding: 60px 0;
  text-align: center;
  background-color: #f9f9f9;
  
}

.why-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 40px;
  color: #ff7f00;
}

.features-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.feature-box {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s;
  margin-bottom: 30px;
}

.feature-box:hover {
  transform: translateY(-10px);
}

.feature-box .icon {
  font-size: 3rem;
  color: #ff7f00;
  margin-bottom: 20px;
}

.feature-box h4 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  font-weight: bold;
}

.feature-box p {
  font-size: 1rem;
  color: #555;
}

/* Media Queries */
@media (max-width: 768px) {
  .why-choose-us .feature-box {
    margin-bottom: 40px;
  }
}

/* News Letter Section*/
/* Styling for the newsletter section */
/* Styling for the newsletter section */
.newsletter-section {
  background-image: url(https://img.freepik.com/free-vector/copy-space-blue-circuits-digital-background_23-2148821699.jpg?size=626&ext=jpg&ga=GA1.1.1819120589.1728172800&semt=ais_hybrid);
  padding: 50px 0;

}

.newsletter-title {
  font-size: 1.8rem;
  font-weight: bold;
}

.newsletter-description {
  margin-bottom: 20px;
}

/* Newsletter Box Styling */
.newsletter-box {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #e5e5e5;
}

.newsletter-form {
  max-width: 400px;
}

.newsletter-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
  .newsletter-section .row {
    flex-direction: column-reverse;
  }

  .newsletter-image {
    margin-bottom: 20px;
  }
}

/* Footer Section*/
.footer-section {
  background-color: white;
  padding: 40px 0;
  border-top: 1px solid #e5e5e5;
}

.footer-title {
  color: blue;
  font-size: 24px;
  font-weight: bold;
}

.footer-hr {
  margin: 10px 0;
  border-color: #000;
  border-width: 2px;
}

.footer-description {
  margin-top: 10px;
  color: #333;
  line-height: 1.6;
}

.social-icons {
  margin-top: 20px;
}

.social-icons .icon {
  font-size: 24px;
  color: #000;
  margin-right: 15px;
  transition: color 0.3s;
}

.social-icons .icon:hover {
  color: #007bff;
}

.footer-column h5 {
  font-weight: bold;
  margin-bottom: 20px;
  font-size: 14px;
}

.footer-column ul {
  list-style-type: none;
  padding: 0;
}

.footer-column ul li {
  margin-bottom: 10px;
}

.footer-column ul li a {
  text-decoration: none;
  color: #000;
  transition: color 0.3s;
  font-family: Roboto Flex, Arial, Sans-Serif;
}

.footer-column ul li a:hover {
  color: #007bff;
}

.subscribe-button {
  margin-top: 10px;
  border: none;
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  font-size: 14px;
  transition: background-color 0.3s;
}

.subscribe-button:hover {
  background-color: #0056b3;
}

.footer-bottom-section {
  background-color: white;
  padding: 20px 0;
  border-top: 1px solid #e5e5e5;
  font-family: Roboto Flex, Arial, Sans-Serif;
}

.footer-bottom-hr {
  margin: 20px 0;
  border-color: #ccc;
}

.footer-links {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.footer-links li {
  display: inline-block;
}

.footer-links li a {
  text-decoration: none;
  color: #000;
  transition: color 0.3s;
}

.footer-links li a:hover {
  color: #007bff;
}

.feedback-button {
  margin-top: 20px;
  border: none;
  background-color: #007bff;
  color: white;
  padding: 10px 30px;
  font-size: 16px;
  transition: background-color 0.3s;
}

.feedback-button:hover {
  background-color: #0056b3;
}
