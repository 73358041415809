.blogs-page {
    padding: 50px 0;
    background-color: #f9f9f9;
  }
  
  .blog-heading {
    font-size: 2.5rem;
    margin-bottom: 40px;
    color: #333;
  }
  
  .blog-card {
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 20px;
    transition: transform 0.3s ease;
  }
  
  .blog-card:hover {
    transform: scale(1.05);
  }
  
  .blog-image {
    height: 200px;
    object-fit: cover;
  }
  
  .blog-title {
    font-size: 1.25rem;
    margin: 10px 0;
    color: white;
    text-align: center;
  }
  
  .blog-content {
    padding: 15px;
    font-size: 0.95rem;
  }
  